import "./src/fonts/fonts.css";
import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

// Registering the link resolver for Prismic
const { registerLinkResolver } = require("gatsby-source-prismic-graphql");
const { linkResolver } = require("./src/utils/linkResolver");

registerLinkResolver(linkResolver);

// Gatsby's wrapPageElement function
export const wrapPageElement = ({ element, props }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>;
};

// Adding the HubSpot script to the HTML document on client entry
export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/46082381.js";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    document.head.appendChild(script);
  }
};
